/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql, withPrefix } from 'gatsby';

function SEO({ description, lang, meta, title, image }) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        siteURL
                    }
                }
            }
        `
    );

    const metaDescription = description || site.siteMetadata.description;
    const metaImage = image ? `${site.siteMetadata.siteURL}${image}` : '';

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
                {
                    name: 'description',
                    content: metaDescription,
                },
                {
                    property: 'og:title',
                    content: title,
                },
                {
                    property: 'og:description',
                    content: metaDescription,
                },
                {
                    property: 'og:type',
                    content: 'website',
                },
                {
                    property: 'og:image',
                    content: metaImage,
                },
                {
                    name: 'twitter:card',
                    content: 'summary',
                },
                {
                    name: 'twitter:creator',
                    content: site.siteMetadata.author,
                },
                {
                    name: 'twitter:title',
                    content: title,
                },
                {
                    name: 'twitter:image',
                    content: metaImage,
                },
                {
                    name: 'twitter:description',
                    content: metaDescription,
                },
            ].concat(meta)}
            link={[
                {
                    rel: 'icon',
                    href: `${withPrefix('/')}img/favicon.ico`,
                },
                {
                    rel: 'icon',
                    type: 'image/png',
                    href: `${withPrefix('/')}img/favicon-16x16.png`,
                    sizes: '16x16',
                },
                {
                    rel: 'icon',
                    type: 'image/png',
                    href: `${withPrefix('/')}img/favicon-32x32.png`,
                    sizes: '32x32',
                },
                {
                    rel: 'icon',
                    type: 'image/png',
                    href: `${withPrefix('/')}img/favicon-96x96.png`,
                    sizes: '96x96',
                },
                {
                    rel: 'icon',
                    type: 'image/png',
                    href: `${withPrefix('/')}img/favicon-128x128.png`,
                    sizes: '128x128',
                },
                {
                    rel: 'icon',
                    type: 'image/png',
                    href: `${withPrefix('/')}img/favicon-196x196.png`,
                    sizes: '196x196',
                },
            ]}
        />
    );
}

SEO.defaultProps = {
    lang: 'en',
    meta: [],
    description: '',
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
};

export default SEO;
